import * as React from 'react'

import {
  BestSellers,
  BestSellersProps,
} from '@thg-commerce/enterprise-widget-best-sellers'
import { ProductProps } from '@thg-commerce/enterprise-widget-best-sellers/src/BestSellers'

import {
  GlobalFourBestSellers,
  GlobalProductCardScroller,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { i18n } from '@thg-commerce/enterprise-core'
import { spacing, styled } from '@thg-commerce/enterprise-theme'

import { BaseWidgetProps } from '../types'
import {
  SwatchShape,
  SwatchSize,
} from '@thg-commerce/gravity-elements/Swatch/types'
import { ProductBlock } from '@thg-commerce/enterprise-graphql/aurora-schema'

export type GlobalProductCardScrollerProps = BaseWidgetProps &
  Omit<GlobalProductCardScroller, 'query' | 'productSkuListNew'> & {
    productVariantList?: ProductProps[]
  }

export interface BestSellersRendererStandardProps
  extends Omit<
    GlobalFourBestSellers & BaseWidgetProps,
    'ProductOne' | 'ProductTwo' | 'ProductThree' | 'ProductFour' | 'query'
  > {
  ProductVariantOne?: ProductProps
  ProductVariantTwo?: ProductProps
  ProductVariantThree?: ProductProps
  ProductVariantFour?: ProductProps
  productBlockOne?: ProductBlock
  productBlockTwo?: ProductBlock
  productBlockThree?: ProductBlock
  productBlockFour?: ProductBlock
}

export type BestSellersRendererProps =
  | (BestSellersRendererStandardProps & { widgetIndex: number })
  | (GlobalProductCardScrollerProps & { widgetIndex: number })

const StyledBestSellers = styled(BestSellers)`
  margin: ${spacing(2)} auto;
  padding: 0 ${spacing(3)};
  max-width: ${(props) => props.theme.site.siteWidth};
`

export const BestSellersWidgetRenderer = (props: BestSellersRendererProps) => {
  const i18nText = {
    title: i18n('general.productbuynow.text'),
    ariaLabel: i18n('general.productbuynow.text'),
    quickBuyTitle: i18n('general.product.quickbuy.text'),
    fulfilmentMethodsText: {
      clickAndCollect: {
        isAvailable: i18n('account.orderdetail.clickandcollect.text'),
        isNotAvailable: i18n('product.clickandcollect.unavailable.text'),
      },
      homeDelivery: {
        isAvailable: i18n('product.delivery.home.instock.text'),
        isNotAvailable: i18n('product.delivery.home.unavailable.text'),
        isOutOfStock: i18n('product.delivery.home.outofstock.text'),
      },
      storeDelivery: {
        isAvailable: i18n('product.delivery.store.available.text'),
      },
    },
    swatchI18nText: {
      swatchTooltipUnavailable: i18n(
        'product.productoptions.swatch.tooltip.unavailable.text',
      ),
      swatchTooltipCloseButton: i18n(
        'product.productoptions.swatch.tooltip.close.text',
      ),
    },
    rrpText: i18n('product.price.rrp.text'),
  }

  if (props.__typename === 'GlobalFourBestSellers') {
    const productBlockList = [
      props.productBlockOne
        ? {
            ...props.productBlockOne,
            title: {
              value: props.productBlockOne.title,
            },
            price: {
              ...props.productBlockOne.price,
              rrpText: i18nText.rrpText,
            },
            review: props.productBlockOne.reviews && {
              starRating: props.productBlockOne.reviews?.averageScore,
              numberOfReviews: props.productBlockOne.reviews?.total,
            },
            marketedSpecialOffer: props.productBlockOne.marketedSpecialOffer
              ? {
                  ...props.productBlockOne.marketedSpecialOffer,
                  title: props.productBlockOne.marketedSpecialOffer.titleText,
                  description:
                    props.productBlockOne.marketedSpecialOffer.descriptionText,
                  onlyDisplayOfferBadge: true,
                }
              : undefined,
            swatch: props.productBlockOne.colourSwatches
              ? {
                  shape: SwatchShape.CIRCLE,
                  size: SwatchSize.Small,
                  colours: props.productBlockOne.colourSwatches,
                  i18nText: {
                    unavailableText:
                      i18nText.swatchI18nText.swatchTooltipUnavailable,
                    closeButtonText:
                      i18nText.swatchI18nText.swatchTooltipCloseButton,
                  },
                }
              : undefined,
          }
        : null,
      props.productBlockTwo
        ? {
            ...props.productBlockTwo,
            title: {
              value: props.productBlockTwo.title,
            },
            price: {
              ...props.productBlockTwo.price,
              rrpText: i18nText.rrpText,
            },
            review: props.productBlockTwo.reviews && {
              starRating: props.productBlockTwo.reviews?.averageScore,
              numberOfReviews: props.productBlockTwo.reviews?.total,
            },
            marketedSpecialOffer: props.productBlockTwo.marketedSpecialOffer
              ? {
                  ...props.productBlockTwo.marketedSpecialOffer,
                  title: props.productBlockTwo.marketedSpecialOffer.titleText,
                  description:
                    props.productBlockTwo.marketedSpecialOffer.descriptionText,
                  onlyDisplayOfferBadge: true,
                }
              : undefined,
            swatch: props.productBlockTwo.colourSwatches
              ? {
                  shape: SwatchShape.CIRCLE,
                  size: SwatchSize.Small,
                  colours: props.productBlockTwo.colourSwatches,
                  i18nText: {
                    unavailableText:
                      i18nText.swatchI18nText.swatchTooltipUnavailable,
                    closeButtonText:
                      i18nText.swatchI18nText.swatchTooltipCloseButton,
                  },
                }
              : undefined,
          }
        : null,
      props.productBlockThree
        ? {
            ...props.productBlockThree,
            title: {
              value: props.productBlockThree.title,
            },
            price: {
              ...props.productBlockThree.price,
              rrpText: i18nText.rrpText,
            },
            review: props.productBlockThree.reviews && {
              starRating: props.productBlockThree.reviews?.averageScore,
              numberOfReviews: props.productBlockThree.reviews?.total,
            },
            marketedSpecialOffer: props.productBlockThree.marketedSpecialOffer
              ? {
                  ...props.productBlockThree.marketedSpecialOffer,
                  title: props.productBlockThree.marketedSpecialOffer.titleText,
                  description:
                    props.productBlockThree.marketedSpecialOffer
                      .descriptionText,
                  onlyDisplayOfferBadge: true,
                }
              : undefined,
            swatch: props.productBlockThree.colourSwatches
              ? {
                  shape: SwatchShape.CIRCLE,
                  size: SwatchSize.Small,
                  colours: props.productBlockThree.colourSwatches,
                  i18nText: {
                    unavailableText:
                      i18nText.swatchI18nText.swatchTooltipUnavailable,
                    closeButtonText:
                      i18nText.swatchI18nText.swatchTooltipCloseButton,
                  },
                }
              : undefined,
          }
        : null,
      props.productBlockFour
        ? {
            ...props.productBlockFour,
            title: {
              value: props.productBlockFour.title,
            },
            price: {
              ...props.productBlockFour.price,
              rrpText: i18nText.rrpText,
            },
            marketedSpecialOffer: props.productBlockFour.marketedSpecialOffer
              ? {
                  ...props.productBlockFour.marketedSpecialOffer,
                  title: props.productBlockFour.marketedSpecialOffer.titleText,
                  description:
                    props.productBlockFour.marketedSpecialOffer.descriptionText,
                  onlyDisplayOfferBadge: true,
                }
              : undefined,
            review: props.productBlockFour.reviews && {
              starRating: props.productBlockFour.reviews?.averageScore,
              numberOfReviews: props.productBlockFour.reviews?.total,
            },
            swatch: props.productBlockFour.colourSwatches
              ? {
                  shape: SwatchShape.CIRCLE,
                  size: SwatchSize.Small,
                  colours: props.productBlockFour.colourSwatches,
                  i18nText: {
                    unavailableText:
                      i18nText.swatchI18nText.swatchTooltipUnavailable,
                    closeButtonText:
                      i18nText.swatchI18nText.swatchTooltipCloseButton,
                  },
                }
              : undefined,
          }
        : null,
    ].filter((productBlock) => productBlock !== null)
    const products = [
      props.ProductVariantOne && {
        ...props.ProductVariantOne,
        price: {
          rrpText: i18nText.rrpText,
          ...props.ProductVariantOne.price,
        } as ProductProps['price'],
        externalIdentifier: props.ProductVariantOne?.externalIdentifier,
        isCheckStock: props.ProductVariantOne.isCheckStock,
        isOrderInStore: props.ProductVariantOne.isOrderInStore,
        inStock: props.ProductVariantOne.inStock,
        inStockLocations: props.ProductVariantOne.inStockLocations,
        weightGroups: props.ProductVariantOne.weightGroups,
        leadTime: props.ProductVariantOne.leadTime,
        isBookable: props.ProductVariantOne.isBookable,
        icons: props.ProductVariantOne.icons,
        content: props?.ProductOneNew?.product?.content,
        brand: {
          name: props.ProductVariantOne.brand?.name || '',
          imageUrl: props.ProductVariantOne.brand?.imageUrl,
        },
        swatch: props.ProductVariantOne.colourSwatches
          ? {
              shape: SwatchShape.CIRCLE,
              size: SwatchSize.Small,
              colours: props.ProductVariantOne.colourSwatches,
              i18nText: {
                unavailableText:
                  i18nText.swatchI18nText.swatchTooltipUnavailable,
                closeButtonText:
                  i18nText.swatchI18nText.swatchTooltipCloseButton,
              },
            }
          : undefined,
      },
      props.ProductVariantTwo && {
        ...props.ProductVariantTwo,
        price: {
          ...props.ProductVariantTwo.price,
          rrpText: i18nText.rrpText,
        } as ProductProps['price'],
        externalIdentifier: props.ProductVariantTwo?.externalIdentifier,
        isCheckStock: props.ProductVariantTwo.isCheckStock,
        isOrderInStore: props.ProductVariantTwo.isOrderInStore,
        inStock: props.ProductVariantTwo.inStock,
        inStockLocations: props.ProductVariantTwo.inStockLocations,
        weightGroups: props.ProductVariantTwo.weightGroups,
        leadTime: props.ProductVariantTwo.leadTime,
        isBookable: props.ProductVariantTwo.isBookable,
        icons: props.ProductVariantTwo.icons,
        content: props?.ProductTwoNew?.product?.content,
        brand: {
          name: props.ProductVariantTwo.brand?.name || '',
          imageUrl: props.ProductVariantTwo.brand?.imageUrl,
        },
        swatch: props.ProductVariantTwo.colourSwatches
          ? {
              shape: SwatchShape.CIRCLE,
              size: SwatchSize.Small,
              colours: props.ProductVariantTwo.colourSwatches,
              i18nText: {
                unavailableText:
                  i18nText.swatchI18nText.swatchTooltipUnavailable,
                closeButtonText:
                  i18nText.swatchI18nText.swatchTooltipCloseButton,
              },
            }
          : undefined,
      },
      props.ProductVariantThree && {
        ...props.ProductVariantThree,
        price: {
          ...props.ProductVariantThree.price,
          rrpText: i18nText.rrpText,
        } as ProductProps['price'],
        externalIdentifier: props.ProductVariantThree?.externalIdentifier,
        isCheckStock: props.ProductVariantThree.isCheckStock,
        isOrderInStore: props.ProductVariantThree.isOrderInStore,
        inStock: props.ProductVariantThree.inStock,
        inStockLocations: props.ProductVariantThree.inStockLocations,
        weightGroups: props.ProductVariantThree.weightGroups,
        leadTime: props.ProductVariantThree.leadTime,
        isBookable: props.ProductVariantThree.isBookable,
        icons: props.ProductVariantThree.icons,
        content: props?.ProductThreeNew?.product?.content,
        brand: {
          name: props.ProductVariantThree.brand?.name || '',
          imageUrl: props.ProductVariantThree.brand?.imageUrl,
        },
        swatch: props.ProductVariantThree.colourSwatches
          ? {
              shape: SwatchShape.CIRCLE,
              size: SwatchSize.Small,
              colours: props.ProductVariantThree.colourSwatches,
              i18nText: {
                unavailableText:
                  i18nText.swatchI18nText.swatchTooltipUnavailable,
                closeButtonText:
                  i18nText.swatchI18nText.swatchTooltipCloseButton,
              },
            }
          : undefined,
      },
      props.ProductVariantFour && {
        ...props.ProductVariantFour,
        price: {
          ...props.ProductVariantFour.price,
          rrpText: i18nText.rrpText,
        } as ProductProps['price'],
        externalIdentifier: props.ProductVariantFour?.externalIdentifier,
        isCheckStock: props.ProductVariantFour.isCheckStock,
        isOrderInStore: props.ProductVariantFour.isOrderInStore,
        inStock: props.ProductVariantFour.inStock,
        inStockLocations: props.ProductVariantFour.inStockLocations,
        weightGroups: props.ProductVariantFour.weightGroups,
        leadTime: props.ProductVariantFour.leadTime,
        isBookable: props.ProductVariantFour.isBookable,
        icons: props.ProductVariantFour.icons,
        content: props?.ProductFourNew?.product?.content,
        brand: {
          name: props.ProductVariantFour.brand?.name || '',
          imageUrl: props.ProductVariantFour.brand?.imageUrl,
        },
        swatch: props.ProductVariantFour.colourSwatches
          ? {
              shape: SwatchShape.CIRCLE,
              size: SwatchSize.Small,
              colours: props.ProductVariantFour.colourSwatches,
              i18nText: {
                unavailableText:
                  i18nText.swatchI18nText.swatchTooltipUnavailable,
                closeButtonText:
                  i18nText.swatchI18nText.swatchTooltipCloseButton,
              },
            }
          : undefined,
      },
    ].filter((product) => product)

    const bestSellersProps: BestSellersProps = {
      title: props.Title ? { text: props.Title } : undefined,
      productBlocks: productBlockList.length > 0 ? productBlockList : products,
      shouldDisplayTitle: !props.disableTitle || props.disableTitle === 'false',
      widgetIndex: props.widgetIndex,
      fulfilmentMethodsText: i18nText.fulfilmentMethodsText,
      displayQuickBuyButton: false,
    }
    return <StyledBestSellers {...bestSellersProps} />
  }

  if (
    props.__typename === 'GlobalProductCardScroller' &&
    props.productVariantList
  ) {
    const globalProductCardScrollerProps: BestSellersProps = {
      productBlocks: props.productVariantList
        .filter((product) => product)
        .map((product) => {
          return {
            ...product,
            button: i18nText,
            price: {
              rrpText: i18nText.rrpText,
              ...product.price,
            } as ProductProps['price'],
          }
        }),
      title: (props.title && { text: props.title }) || undefined,
      widgetIndex: props.widgetIndex,
      fulfilmentMethodsText: i18nText.fulfilmentMethodsText,
      displayQuickBuyButton: false,
    }

    return <StyledBestSellers {...globalProductCardScrollerProps} />
  }

  return null
}
